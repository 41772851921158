<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_06.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O alicerce ou fundação, conhecido como “base”, é a parte da
                  construção localizada embaixo da casa, encravada no solo para
                  sustentação das paredes. O alicerce é que vai receber e
                  resistir ao peso de toda a casa, seja das paredes, assim como
                  da cobertura quando for executada.
                </p>
                <p>
                  Inicialmente, são escavadas no solo as valas onde será erguida
                  cada parede e compactado o piso da cava, e então o espaço é
                  preenchido com o material — pedra, bloco de concreto,
                  concreto, concreto armado —, escolhido de acordo com a
                  necessidade. Há casos que além das vigas baldrame, nas quinas
                  são executadas as estacas, que consistem em elementos
                  profundos ao modo de colunas fincadas no solo.
                </p>
                <p>
                  Nas construções das comunidades de Mariana, tanto no período
                  colonial como nos séculos seguintes, foi amplamente utilizado
                  o alicerce de pedra sobre pedra (pedra seca) preenchendo-se os
                  espaços que sobravam com pedras menores, ou pedras unidas com
                  massa de barro e respaldadas (calçadas no topo) com lajes do
                  mesmo material (pedra plana).
                </p>
                <p>
                  Com a chegada de outros materiais na região, como cimento e
                  posteriormente os blocos de concreto, as novas construções
                  foram se utilizando dessas outras tecnologias, sem, no
                  entanto, deixar de ser usada a fundação de pedras, mesmo em
                  novas edificações. Cabe ressaltar que a exemplo de Bento
                  Rodrigues, Camargos, Pedras, era comum o aproveitamento dos
                  alicerces que restavam nos terrenos, que pertenceram a antigas
                  edificações.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <img
            width="80%"
            src="../../assets/conteudos_casas_construcao_36.png"
          />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa construída sobre alicerce de blocos de concreto em um terreno
            da comunidade de Paracatu de Cima. Foto: Vistoria técnica da
            Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_37.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Alicerce de pedra seca de galinheiro em um terreno da comunidade de
            Pedras. Foto: Vistoria técnica da Cáritas, 2019.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_38.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa com fundação de pedras de modo elevado do solo, construída em
            um terreno da comunidade de Camargos. Foto: Vistoria técnica da
            Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_tecnica_preserva' }"
            color="#EDBD43"
          >
            <strong>Conhecer "A técnica para preservar"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa /",
          href: "conteudos_tematicos_casas",
        },

        {
          text: "Técnicas Construtivas Tradicionais /",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },

        {
          text: "O Alicerce",
          href: "conteudos_tematicos_casas_alicerce",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
